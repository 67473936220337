import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import queryString from "query-string";
import { RootState } from "&store/store";
import { getCurrentUser } from "&config/getCurrentUser";
import { reportsActions } from "./reports.slice";
import SettlementReport from "./settlements";
import TransactionReport from "./transactions";
import BillingReport from "./billing";
import BalanceInOutReport from "./balance-in-out";

type ReduxProps = ConnectedProps<typeof connector>;

const ReportsComponent = (props: ReduxProps) => {
  const {
    state,
    searchTransactionReports,
    getTransactionReports,
    searchSettlementReports,
    searchBillingReports,
    getBillingDetail,
    updateStatus,
    getServices,
    setType,
    location,
    token,
    reset,
  } = props;
  const { data, count, services, analytics } = state;

  const user = getCurrentUser(token);
  const { type: reportType } = queryString.parse(location);

  useEffect(() => {
    reset();
  }, [reportType, reset, setType]);
  return (
    <>
      {reportType === "settlement-report" && <SettlementReport onSearch={searchSettlementReports} data={data} />}
      {reportType === "transaction-report" && (
        <TransactionReport
          onSearch={searchTransactionReports}
          data={data}
          user={user}
          updateStatus={updateStatus}
          count={count}
          token={token}
        />
      )}
      {reportType === "billing-report" && (
        <BillingReport onSearch={searchBillingReports} data={data} onDetail={getBillingDetail} />
      )}
      {reportType === "cash-in-out" && (
        <BalanceInOutReport onSearch={searchBillingReports} getTransactionReports={getTransactionReports} getServices={getServices} services={services} analytics={analytics}/>
      )}
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProp) => ({
  state: state.reports,
  location: ownProp.location.search,
  token: state.login.token,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  setType: reportsActions.setType,
  updateStatus: reportsActions.updateBillingStatus,
  setBillingType: reportsActions.setBillingType,
  searchTransactionReports: reportsActions.searchTransactionReports,
  getTransactionReports: reportsActions.getTransactionReports,
  searchSettlementReports: reportsActions.searchSettlementReports,
  searchBillingReports: reportsActions.searchBillingReports,
  getBillingDetail: reportsActions.getBillingDetail,
  getServices: reportsActions.getServices,
  reset: reportsActions.reset,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const ReportsComponentRedux = connector(ReportsComponent);

export { ReportsComponentRedux as ReportsComponent };
