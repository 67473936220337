import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";

import { CsvJob } from "./csvJob.type";

const url = process.env.REACT_APP_CSV_JOB_URL;

/**
 * Initial state object
 */
const initialState: CsvJob = {
  results: [],
  count: 0,
};

/**
 * Thunks are used to dispatch actions that return functions rather than objects,
 * usually used for making api calls or dispatching async actions.
 * Thunks are dispatched in the same way regular actions are dispatched.
 * A slice can have multiple thunks
 */
const makeCsvJobApiCall = createAsyncThunk("csvJob/makeCsvJobApiCallStatus", async (body: any) => {});

const getCSVJobs = createAsyncThunk(
  "csvJob/getCSVJobs",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      console.log('body', body)
      const response = await trackPromise(
        axios.get(`${url}/csv-jobs`, {
          params: {
            ...body,
          },
          headers,
        })
      );
      console.log("Success response from transaction report :", response.data);
      dispatch(csvJobActions.setData(response.data.data.data));
      dispatch(csvJobActions.setCount(response.data.data.count));
      return response.data.subs;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const csvJobSlice = createSlice({
  name: "csvJob",
  initialState: initialState,
  reducers: {
    setCsvJob: (state, action) => {
      return { ...state, ...action.payload };
    },
    setData: (state, action) => {
      state.results = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(makeCsvJobApiCall.pending, (state, action) => {});
    builder.addCase(makeCsvJobApiCall.fulfilled, (state, action) => {});
    builder.addCase(makeCsvJobApiCall.rejected, (state, action) => {});
  },
});

export const csvJobReducer = csvJobSlice.reducer;

export const csvJobActions = { ...csvJobSlice.actions, makeCsvJobApiCall, getCSVJobs };
