import React from "react";
import { PieChart, Pie, ResponsiveContainer, Cell, Tooltip,Legend } from "recharts";
import Box from "@mui/material/Box";

interface Props {
  data: any;
  blackProp? : boolean;
  hasLegend? : boolean;
  title?: string;
}
const COLORS = ['#6631F7', '#7E52F8', '#AF93FB', '#D3C4FD'];
const PieChartComponent = (props: Props) => {
  const { data,blackProp, title,hasLegend } = props;
  return (
    <Box
      sx={{
        borderRadius: "8px",
        height: "370px",
        width: "100%",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        justifyContent: "space-between",
        padding: "1.5rem",
      }}
    >
      <Box sx={{ color: "#111111", fontSize: "16px", fontWeight: "600" }}>
        {title ? title : "Transaction Rate"}
      </Box>
      <ResponsiveContainer height={250} width="100%">
        <PieChart width={700} height={250}>
       {hasLegend && <Legend layout="vertical" verticalAlign="middle" align="right"  payload={data.slice(0, 5).map((entry) => ({
            value: entry.name,
            type: 'circle',
            color: COLORS[data.indexOf(entry) % COLORS.length],
          }))}/>}
          <Pie
            data={data}
            innerRadius={90}
            outerRadius={110}
            fill="#8884d8"
            paddingAngle={0}
            dataKey="value"
          >
            <Cell fill={"#6631F7"} />
            <Cell fill={"#CAB2FF"} />
            {blackProp && <Cell fill={"#000000"} />}
          </Pie>
          <Tooltip formatter={(value: any) => new Intl.NumberFormat('en').format(value)}/>
        </PieChart>
      </ResponsiveContainer>
     {!hasLegend && <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <Box sx={{ display: "flex", gap: 8 }}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Box
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "100px",
                background: "#6631F7 0% 0% no-repeat padding-box",
              }}
            ></Box>
            <Box>Successful</Box>
          </Box>
          { blackProp &&  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Box
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "100px",
                background: "#000000 0% 0% no-repeat padding-box",
              }}
            ></Box>
            <Box>Pending</Box>
          </Box>}
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Box
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "100px",
                background: "#CAB2FF 0% 0% no-repeat padding-box",
              }}
            ></Box>
            <Box>Rejected</Box>
          </Box>
        </Box>
      </Box>}
    </Box>
  );
};

export { PieChartComponent };
