import React from "react";
import { Route, Switch } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { HomeComponent } from "&features/home/home.component";
import { RootState } from "&store/store";
import { ProtectedRoute } from "&route/protectedRoute";
import { ReportsComponent } from "&features/reports/reports.component";
import { SettlementComponent } from "&features/settlement/settlement.component";
import { SettlementDetailViewComponent } from "&features/reports/settlementDetailView";
import { LogoutComponent } from "&features/logout/logout.component";
import { SettlementFormComponent } from "&features/settlement/settlementForm";
import { MerchantsComponent } from "&features/merchants/merchants.component";
import { loginActions } from "./features/login/login.slice";
import { reportsActions } from "./features/reports/reports.slice";
import WaleeDrawer from "&styled/drawer";
import { MerchantFormComponent } from "&features/merchants/merchantForm";
import { CarriersComponent } from "&features/carriers/carriers.component";
import { CarrierFormComponent } from "&features/carriers/carrierForm";
import { ServicesComponent } from "&features/services/services.component";
import { ServiceFormComponent } from "&features/services/serviceForm";
import { UsersComponent } from "&features/users/users.component";
import { RolesComponent } from "&features/roles/roles.component";
import { getCurrentUser } from "&config/getCurrentUser";
import { RoleFormComponent } from "&features/roles/roleForm";
import { UserFormComponent } from "&features/users/userForm";
import { AccountProfileFormComponent } from "&features/users/accountProfileForm";
import { ChangePasswordFormComponent } from "&features/users/changePassword";
import { SubscriptionsComponent } from "&features/subscriptions/subscriptions.component";
import { OneTinePurchaseComponent } from "&features/oneTinePurchase/oneTinePurchase.component";
import { CarriersHistoryComponent } from "&features/carriers/history";
import { MerchantHistoryComponent } from "&features/merchants/history";
import { CreditCardRedirectComponent } from "&features/creditCardRedirect/creditCardRedirect.component";
import { HostedCheckoutComponent } from "&features/hostedCheckout/hostedCheckout.component";
import { LoanRequestsComponent } from "&features/loanRequests/loanRequests.component";
import { ProductsComponent } from "&features/products/products.component";
import { ProductFormComponent } from "&features/products/productForm";
import { FileRecordComponent } from "&features/loanRequests/fileRecord/fileRecord.component";
import { AccountBalanceFormComponent } from "&features/merchants/accountBalanceForm.tsx";
import { merchantsActions } from "&features/merchants/merchants.slice";
import { LoanAccountBalanceComponent } from "&features/loanAccountBalance/loanAccountBalance.component";
import { LoanAccountBalanceDetailComponent } from "&features/loanAccountBalanceDetail/loanAccountBalanceDetail.component";
import { TransactionFormComponent } from "&features/loanAccountBalanceDetail/transactionForm";
import { CheckoutLogsComponent } from "&features/checkoutLogs/checkoutLogs.component";
import { CheckoutRequestsComponent } from "&features/checkoutRequests/checkoutRequests.component";
import { MerchantTopupComponent } from "&features/merchantTopup/merchantTopup.component";
import { CollectionComponent } from "&features/collection/collection.component";
import { DisbursementComponent } from "&features/disbursement/disbursement.component";
import { BlacklistsComponent } from "&features/blacklists/blacklists.component";
import { BlacklistFormComponent } from "&features/blacklists/blacklistForm";
import { FawryCheckoutComponent } from "&features/fawryCheckout/fawryCheckout.component";
import { MenaRedirectComponent } from "&features/fawryCheckout/welcomePage/menaRedirect.component";
import { CallCenterComponent } from "&features/callCenter/callCenter.component";
import { CreditCardRedirectSuccessComponent } from "&features/creditCardRedirect/Success";
import { CreditCardRedirectFailedComponent } from "&features/creditCardRedirect/Failed";
import { PackageFormComponent } from "&features/packages/packageForm";
import { CheckoutLinkComponent } from "&features/packages/checkoutLink";
import { PackagesComponent } from "&features/packages/packages.component";
import { CsvJobComponent } from "&features/csvJob/csvJob.component";

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});
type ReduxProps = ConnectedProps<typeof connector>;

const AppRouter = (props: ReduxProps) => {
  const { isLoggedIn, resetReportType, setReportType, setBillingType, getMerchantInRedis, logOut, token } = props;
  const user = getCurrentUser(token);

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route exact path="/easypaisa/cc/:serviceId/:msisdn/:product" component={CreditCardRedirectComponent} />
        <Route exact path="/checkout/mena/:serviceId/:orderId" component={MenaRedirectComponent} />
        <Route
          exact
          path="/callback/stripe/success/:orderId/:serviceId"
          component={CreditCardRedirectSuccessComponent}
        />
        <Route exact path="/callback/stripe/fail/:orderId/:serviceId" component={CreditCardRedirectFailedComponent} />
        <Route exact path="/hosted-checkout/:sessionId" component={HostedCheckoutComponent} />
        <Route exact path="/hosted-checkout/eg/:sessionId" component={FawryCheckoutComponent} />
        <Route exact path="/checkout/via-link/:id" component={CheckoutLinkComponent} />
        <WaleeDrawer
          setType={setReportType}
          setBillingType={setBillingType}
          resetReport={resetReportType}
          logOut={logOut}
          user={user}
          getMerchantInRedis={getMerchantInRedis}
        >
          <ProtectedRoute exact path="/logout" component={LogoutComponent} validator={isLoggedIn} fallBack="/login" />

          <ProtectedRoute exact path="/dashboard" component={HomeComponent} validator={isLoggedIn} fallBack="/login" />
          <ProtectedRoute exact path="/reports" component={ReportsComponent} validator={isLoggedIn} fallBack="/login" />
          <ProtectedRoute
            exact
            path="/csv-jobs"
            component={CsvJobComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/subscriptions"
            component={SubscriptionsComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          {/* <ProtectedRoute
            exact
            path="/one-time-purchase"
            component={OneTinePurchaseComponent}
            validator={isLoggedIn}
            fallBack="/login"
          /> */}
          <ProtectedRoute
            exact
            path="/loan-requests"
            component={LoanRequestsComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/file-records"
            component={FileRecordComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/settlements"
            component={SettlementComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/settlement-summary/:settlementId"
            component={SettlementDetailViewComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/settlement/form/:settlementId"
            component={SettlementFormComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/merchants"
            component={MerchantsComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/merchants/form/:merchantId"
            component={MerchantFormComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/merchants/account-balance/:merchantId"
            component={AccountBalanceFormComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/merchants/history/:merchantId"
            component={MerchantHistoryComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/carriers"
            component={CarriersComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/carriers/form/:carrierId"
            component={CarrierFormComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/products"
            component={ProductsComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/products/form/:productId"
            component={ProductFormComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/carriers/history/:carrierId"
            component={CarriersHistoryComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/packages"
            component={PackagesComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/packages/form/:packageId"
            component={PackageFormComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/services"
            component={ServicesComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/services/form/:serviceId"
            component={ServiceFormComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute exact path="/users" component={UsersComponent} validator={isLoggedIn} fallBack="/login" />
          <ProtectedRoute
            exact
            path="/users/form/:userId"
            component={UserFormComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/account-profile"
            component={AccountProfileFormComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/change-password"
            component={ChangePasswordFormComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute exact path="/roles" component={RolesComponent} validator={isLoggedIn} fallBack="/login" />
          <ProtectedRoute
            exact
            path="/roles/form/:roleId"
            component={RoleFormComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/merchant-top-ups"
            component={MerchantTopupComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/loan-accounts-balance"
            component={LoanAccountBalanceComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/loan-account-detail/:merchantId"
            component={LoanAccountBalanceDetailComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/loan-account-detail/form/:loanTransactionId"
            component={TransactionFormComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/checkout-logs"
            component={CheckoutLogsComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/checkout-requests"
            component={CheckoutRequestsComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/collection"
            component={CollectionComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/blacklists"
            component={BlacklistsComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/blacklists/form/:blacklistId"
            component={BlacklistFormComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/disbursement"
            component={DisbursementComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />
          <ProtectedRoute
            exact
            path="/call-center"
            component={CallCenterComponent}
            validator={isLoggedIn}
            fallBack="/login"
          />

          <ProtectedRoute exact path="/" component={HomeComponent} validator={isLoggedIn} fallBack="/login" />

          {/* <Route path="/404" render={() => <div>page not found</div>} />
        <Redirect to="/404" /> */}
        </WaleeDrawer>
      </Switch>
    </ThemeProvider>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  isLoggedIn: state.login.isLoggedIn,
  token: state.login.token,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  reset: loginActions.reset,
  logOut: loginActions.reset,
  resetReportType: reportsActions.reset,
  setReportType: reportsActions.setType,
  setBillingType: reportsActions.setBillingType,
  getMerchantInRedis: merchantsActions.getMerchantInRedis,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const AppRouteRedux = connector(AppRouter);

export { AppRouteRedux as AppRouter };
