import React, {  useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PageHeader from "&styled/page-header";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
export default function BalanceInOutReport(props: any) {
  const {  getTransactionReports ,getServices, services, analytics } = props;

  useEffect(()=>{
    (async()=>{
      await getServices();
    })()
  },[getServices]);


  let pkFormatter = new Intl.NumberFormat("en-PK", {
    style: "currency",
    currency: "PKR",
  });

  const renderCashIn = (cashIn) => {
    return (
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{fontWeight: 600}}>Amount</TableCell>
            <TableCell sx={{fontWeight: 600}}>No. Of Transactions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {cashIn.totalAmount && pkFormatter.format(cashIn.totalAmount)}
              </TableCell>
              <TableCell>{cashIn.numberOfTransactions}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    )
  }

  const renderCashOut = (cashOut) => {
    return (
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{fontWeight: 600}}>Amount</TableCell>
            <TableCell sx={{fontWeight: 600}}>No. Of Transactions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {cashOut.totalAmount && pkFormatter.format(cashOut.totalAmount)}
              </TableCell>
              <TableCell>{cashOut.numberOfTransactions}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    )
  }
  return (
    <>
      <PageHeader
        title="Cash In/Out Report"
        onSearch={getTransactionReports}
        type="cash-in-out"
        defaultCurrency="PKR"
        //getDatesValues ={getDatesFromPageHeader}
        services={services}
      />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          "& .purple": {
            backgroundColor: "#F6F2FF",
            color: "#3C3C3C",
          },
        }}
      >
        <Card>
          <CardContent>
           
              <Box sx={{marginTop: 2, display:"flex", justifyContent: "space-between", width: "100%"}}>
                  <Box sx={{display:"flex", flexDirection: "column"}}>
                      <Box sx={{fontSize: "1.5rem", fontWeight: 600}}>Cash In</Box>
                      {renderCashIn(analytics.cashIn)}
                  </Box>
                  <Box sx={{display:"flex", flexDirection: "column"}}>
                      <Box sx={{fontSize: "1.5rem", fontWeight: 600}}>Cash Out</Box>
                       {renderCashOut(analytics.cashOut)}
                  </Box>
              </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
