import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { WALEE_BASE_URL } from "&config/url";
import { Packages } from "./packages.type";

/**
 * Initial state object
 */
const initialState: Packages = {
  data: [],
};

const getPackages = createAsyncThunk(
  "packages/getPackagesCallStatus",
  async (arg: void, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/packages/get`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(WALEE_BASE_URL.concat(pathname), { headers })
      );
      dispatch(packagesActions.setData(response.data));
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const getPackage = createAsyncThunk(
  "packages/getPackageCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/packages/get/${arg}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.get(WALEE_BASE_URL.concat(pathname), { headers })
      );

      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const addPackage = createAsyncThunk(
  "packages/addPackageCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/packages/add`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(WALEE_BASE_URL.concat(pathname), body, { headers })
      );
    
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);
const editPackage = createAsyncThunk(
  "packages/editPackageCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/packages/edit/${body._id}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.put(WALEE_BASE_URL.concat(pathname), body, { headers })
      );
   
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const deletePackage = createAsyncThunk(
  "packages/deletePackageCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/packages/delete/${body}`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.delete(WALEE_BASE_URL.concat(pathname), { headers })
      );
      dispatch(packagesActions.setDeleteRole(body));
    
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

// Checkout link
const generateLink = createAsyncThunk(
  "packages/addPackageCallStatus",
  async (body: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/packages/generate-link`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(
        axios.post(WALEE_BASE_URL.concat(pathname), body, { headers })
      );
    
      return response.data;
    } catch (e:any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);
/**
 * Feature slice Object
 * Automatically generates actions as per reducers
 */
const packagesSlice = createSlice({
  /**
   * Unique feature name
   */
  name: "packages",

  /**
   * Initial state object
   */
  initialState: initialState,

  reducers: {
    setRoles: (state, action) => {
      return { ...state, ...action.payload };
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setDeleteRole: (state, action) => {
      state.data = state.data.filter((val) => val._id !== action.payload);
    },
    reset: () => initialState,
    // Add here reducers
    // ...
  },
  /**
   * Extra reducers are for handling action types.
   * Here thunk actions are handled
   */
  extraReducers: (builder) => {
    // TODO remove extraReducers if there are no thunks
    builder.addCase(getPackages.pending, (state, action) => {
      // Write pending logic here
    });
    builder.addCase(getPackages.fulfilled, (state, action) => {
      // Write success logic here
    });
    builder.addCase(getPackages.rejected, (state, action) => {
      // Write failure logic here
    });
  },
});

/**
 * Reducers are exported so they could be added to store
 */
export const packagesReducer = packagesSlice.reducer;

/**
 * Actions hold the same names as reducers.
 * Actions can be dispached using 'useDispacth' hook,
 * or by 'mapDispatchToProps' in the redux 'connect' function
 */
export const packagesActions = {
  ...packagesSlice.actions,
  getPackages,
  getPackage,
  addPackage,
  editPackage,
  deletePackage,
  generateLink
};
