import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import { WALEE_BASE_URL } from "&config/url";
import { Home } from "./home.type";

/**
 * Initial state object
 */
const initialState: Home = {
  summary: {
    cardsSummary: {},
    subscriptionSummary: [],
    salesSummary: [],
  },
};

const getDashboardSummary = createAsyncThunk(
  "home/getHomeCallStatus",
  async (arg: void, { rejectWithValue, getState, dispatch }: any) => {
    const subscriptionSummaryPath = `/summary/get-subscription-summary`;
    const getSalesPath = `/summary/get-today-sales`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const [subscriptionSummaryResp, getSalesResp] = await Promise.all([
        axios.get(WALEE_BASE_URL.concat(subscriptionSummaryPath), { headers }),
        axios.get(WALEE_BASE_URL.concat(getSalesPath), { headers }),
      ]);
      dispatch(homeActions.setSubscriptionSummaryData(subscriptionSummaryResp.data));
      dispatch(homeActions.setSalesSummaryData(getSalesResp.data));
      return subscriptionSummaryResp.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const getDashboardSummaryCount = createAsyncThunk(
  "home/getHomeCallStatusCount",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/summary/get-dashboard-summary/v2`;
    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      const { platform } = arg;
      /** make api call */
      const response = await axios.get(`${WALEE_BASE_URL.concat(pathname)}?platform=${platform}`, { headers });
      dispatch(homeActions.setCardsData({
        count: response?.data?.count ?? 0,
        platform
      }));
      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const filterDashboardSummary = createAsyncThunk(
  "home/filterHomeCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/summary/filter-subscription`;

    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(axios.post(WALEE_BASE_URL.concat(pathname), arg, { headers }));

      dispatch(homeActions.setSubscriptionSummaryData(response.data));

      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

const filterSalesSummary = createAsyncThunk(
  "home/filterHomeCallStatus",
  async (arg: any, { rejectWithValue, getState, dispatch }: any) => {
    const pathname = `/summary/filter-sales`;

    const token = getState().login.token;
    const headers = {
      "x-auth-token": token,
    };
    try {
      /** make api call */
      const response = await trackPromise(axios.post(WALEE_BASE_URL.concat(pathname), arg, { headers }));

      dispatch(homeActions.setSalesSummaryData(response.data));

      return response.data;
    } catch (e: any) {
      console.error(e);
      return rejectWithValue(e.response?.data);
    }
  }
);

/**
 * Feature slice Object
 * Automatically generates actions as per reducers
 */
const homeSlice = createSlice({
  name: "home",

  initialState: initialState,

  reducers: {
    setHome: (state, action) => {
      return { ...state, ...action.payload };
    },
    setCardsData: (state, action) => {
      const { platform, count } = action.payload;
      state.summary.cardsSummary[platform] = count;
    },
    setSubscriptionSummaryData: (state, action) => {
      state.summary.subscriptionSummary = action.payload;
    },
    setSalesSummaryData: (state, action) => {
      state.summary.salesSummary = action.payload;
    },
    reset: () => initialState,
    // Add here reducers
    // ...
  },
  /**
   * Extra reducers are for handling action types.
   * Here thunk actions are handled
   */
  extraReducers: (builder) => {
    // TODO remove extraReducers if there are no thunks
    builder.addCase(getDashboardSummary.pending, (state, action) => {
      // Write pending logic here
    });
    builder.addCase(getDashboardSummary.fulfilled, (state, action) => {
      // Write success logic here
    });
    builder.addCase(getDashboardSummary.rejected, (state, action) => {
      // Write failure logic here
    });
  },
});

export const homeReducer = homeSlice.reducer;

export const homeActions = {
  ...homeSlice.actions,
  getDashboardSummary,
  filterDashboardSummary,
  filterSalesSummary,
  getDashboardSummaryCount,
};
