import React from "react";
import Box from "@mui/material/Box";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

interface Props {
  title: string;
  legendTitle1?:string;
  legendTitle2?:string;
  heading: string;

  data: Array<any>;
}

const BarChartComponent = (props: Props) => {
  const { title, heading, data,legendTitle1 ,legendTitle2} = props;

  return (
    <Box
      sx={{
        borderRadius: "8px",
        height: "370px",
        width: "100%",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        justifyContent: "space-between",
        padding: "1.5rem",
      }}
    >
      <Box
        sx={{
          color: "#111111",
          fontWeight: "600",
          fontSize: "16px",
          marginBottom: "1rem",
        }}
      >
        {title}
      </Box>
      <Box sx={{ display: "flex", gap: 3 }}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Box
            sx={{
              width: "12px",
              height: "12px",
              borderRadius: "100px",
              background: "#6631F7 0% 0% no-repeat padding-box",
            }}
          ></Box>
          <Box>{legendTitle1 || "Successful"}</Box>
        </Box>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Box
            sx={{
              width: "12px",
              height: "12px",
              borderRadius: "100px",
              background: "#CAB2FF 0% 0% no-repeat padding-box",
            }}
          ></Box>
          <Box>{legendTitle2 || "Unsuccessful"}</Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
        <Box
          sx={{
            fontSize: "12px",
            WebkitTransform: "rotate(-90deg)",
            MozTrasform: "rotate(-90deg)",
            whiteSpace: "nowrap",
            writingMode: "lr-tb",
            marginLeft: "-2rem",
            fontWeight: "bold",
          }}
        >
          {heading}
        </Box>
        {/*Chart*/}

        <ResponsiveContainer width="100%" height="80%">
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="0" vertical={false} />
            <XAxis
              tickLine={false}
              axisLine={false}
              dataKey="operator"
              tick={{ fontSize: "12px", color: "#111111" }}
            />
            <YAxis tickLine={false} axisLine={false} />
            <Tooltip formatter={(value:any) => new Intl.NumberFormat('en').format(value)}/>
            <Bar dataKey="Successful" fill="#8884d8" barSize={12} />
            <Bar dataKey="Failed" fill="#CAB2FF" barSize={12} />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export { BarChartComponent };
