import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Card, Box, CardContent, Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import parsePhoneNumber from 'libphonenumber-js'

import HeaderImg from "../../images/new-header.png";
import JazzcashImg from "../../images/jazzcash.png";
import JazzcashSmallImg from "../../images/jazzcash-small.png";
import EasypaisaImg from "../../images/easypaisa.png";
import EasypaisaSmallImg from "../../images/easypaisa-small.png";
import CreditCardImg from "../../images/credit-card.png";
import CreditCardSmallImg from "../../images/credit-card-small.png";
import AlfaImg from "../../images/alfa.png";
import { RootState } from "&store/store";
import { cleanupMobile } from "&utils/cleanupMobile";
import { hostedCheckoutActions } from "./hostedCheckout.slice";
import ErrorScreen from "./error";
import WaitingModal from "./waitingModal";
import styles from "./hostedCheckout.module.css";
import { infoAlert } from "&config/swalGenerator";

type ReduxProps = ConnectedProps<typeof connector>;

const determineCountryCodeAndMask=(mobileNumber)=> {
  const phoneNumber = parsePhoneNumber(mobileNumber.startsWith("+") ? mobileNumber : `+${mobileNumber}`);
  if (phoneNumber) {
    const countryCode = `+${phoneNumber.countryCallingCode}`;
    const inputMask = phoneNumber.formatNational();
    
    return { countryCode, inputMask };
  }

  return null; // Invalid or unsupported phone number
}

const HostedCheckoutComponent = (props: ReduxProps) => {
  const { sessionId, getSessionInfo, checkout,checkoutWithStripe } = props;
  const [waitingModalOpen, setWaitingModalOpen] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState("waiting");
  const [errorMessage, setErrorMessage] = useState("");
  const [sessionInfo, setSessionInfo] = useState<any>({});
  const [error, setError] = useState("");
  const [countryCode, setCountryCode] = useState("+92")
  const [inputMask, setInputMask] = useState("999 99999999")
  const [mobileView, setMobileView] = useState(false);
  const [data, setData] = useState({
    paymentProvider: "Easypaisa",
    mobileNumber: "",
    email: "",
    cnic: "",
  });

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const handleWaitingModalClose = () => {
    setWaitingModalOpen(false);
  };
  const validation = Yup.object().shape({
    paymentProvider: Yup.string().required("Please select payment provider"),
    mobileNumber: Yup.string()
      .trim()
      .min(9)
      .required("Please provide valid mobile number"),
    email: Yup.string()
    .trim()
    .when("paymentProvider",{
      is: "Easypaisa",
      then: Yup.string().required("Please provide valid email address")
         .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email address"
      ),
    }),
    //email: Yup.string()
    //  .required("Please provide valid email")
    //  .matches(
    //    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    //    "Invalid email address"
    //  ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: validation,
  });

  useEffect(() => {
    (async () => {
      if (!sessionId) return setError("Session ID not provided");
      try {
        const { payload } = await getSessionInfo(sessionId);
  
        if (payload.errors) {
          setError(payload.errors[0].message);
        }
        const cleanedMobileNumber = cleanupMobile(payload.msisdn);
        setData((prevState) => ({
          ...prevState,
          paymentProvider: payload.paymentMethods.includes("Easypaisa") ? "Easypaisa" :payload.paymentMethods[0],
          email: payload.email || "",
          cnic: payload.cnic || "",
          mobileNumber: cleanedMobileNumber,
        }));
        const result = determineCountryCodeAndMask(payload.msisdn);
 
if (result) {
  const { countryCode, inputMask } = result;
          setCountryCode(countryCode)
          setInputMask(inputMask)

}
        setSessionInfo(payload);
      } catch (e) {
        console.log("error :", e);
      }
    })();
  }, [sessionId, getSessionInfo]);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1000
        ? setMobileView(true)
        : setMobileView(false);
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const handlePaymentProviders = (e, formik) => {
    const val = e.target.value;
    formik.setFieldValue("paymentProvider", val);
  };

  const handleMobileChange = (e, formik) => {
    const val = e.target.value.replace(/\s/g, "").trim();
    formik.setFieldValue("mobileNumber", val);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 32) {
      e.preventDefault();
    }
  };
  const handleSubmit = async (vals) => {
    let msisdn;
    if (!vals.mobileNumber.startsWith("0")) {
      if (vals.mobileNumber.replace(" ", "").length !== 10 && vals.mobileNumber.startsWith("03")) {
        return formik.setFieldError("mobileNumber", "Invalid Mobile Number");
      }
      msisdn = `0${vals.mobileNumber.replace(" ", "")}`;
    } else {
      if (vals.mobileNumber.replace(" ", "").length !== 11 && vals.mobileNumber.startsWith("03")) {
        return formik.setFieldError("mobileNumber", "Invalid Mobile Number");
      }
      msisdn = `${vals.mobileNumber.replace(" ", "")}`;
    }

    const serviceProvider = vals.paymentProvider.toLowerCase();
    
    if(serviceProvider === "jazzcash"){
      if(sessionInfo.jcServiceName !== "walee-jazzcash"){
        if(!vals.cnic) return infoAlert("Missing", "CNIC is required")
      }
    }
  
    const {
      serviceId,
      redirectUrl: merchantUrl,
    } = sessionInfo;

    const paymentData = {
      provider: serviceProvider,
      email: vals.email,
      serviceId,
      msisdn,
      cnic: vals.cnic,
      sessionId,
    };

    if (vals.paymentProvider === "Credit Card") {
      const params = {
        sessionId
      };
      setWaitingModalOpen(true);

      const {payload} = await checkoutWithStripe(params);
      if(payload.result?.url){
        window.location.href = payload.result.url;
        return;
      }else{
        setTransactionStatus("error");
        setErrorMessage("Payment rejected");
      }

    }

    let status = "";
    try {
      setWaitingModalOpen(true);
      const { payload: resp } = await checkout(paymentData);
      if (resp.status === "success") {
        setTransactionStatus("success");
        status = `?status=SUCCESS`;
      } else {
        setTransactionStatus("error");
        setErrorMessage(resp.responseDesc || resp.errors[0]?.message);
        status = `?status=FAILED`;
      }
      // redirect back to merchant portal
      const timer = setTimeout(() => {
        window.location.href = merchantUrl + status;
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    } catch (err) {
      setTransactionStatus("error");
      setErrorMessage("Payment is pending");
      status = `?status=FAILED`;
    }
  };

  const renderMobile = () => {
    return (
      <>
        {!error && (
          <Box
            sx={{
              display: "flex",
              height: "90vh",
              overflow: "auto",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Card
              sx={{
                width: matches ? "414px" : "313px",
                overflow: "auto",
              }}
            >
              <CardContent>
                {/*Instructions*/}
                <Box
                  sx={{
                    display: "flex",
                    marginY: "1rem",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box className={styles.heading}>Select Payment Type</Box>
                  <Box className={styles.heading}>
                    ادائیگی کا طریقہ منتخب کریں
                  </Box>
                </Box>
                {/*Payment Providers*/}

                <Grid container spacing={1.5}>
                  {sessionInfo.paymentMethods?.map((pack, index) => (
                    <Grid item lg={4} md={4} sm={12} key={index}>
                      <div
                        className={
                          formik.values.paymentProvider === pack
                            ? `${styles.mobilePriceBoxActive}`
                            : styles.mobilePriceBox
                        }
                      >
                        <label htmlFor={pack}>
                          <input
                            type="radio"
                            value={pack}
                            name="paymentProvider"
                            id={pack}
                            onChange={(e) => handlePaymentProviders(e, formik)}
                            hidden
                          />
                          <Box
                            sx={{
                              display: "flex",
                              cursor: "pointer",
                              gap: 1,
                              alignItems: "center",
                              justifyContent: "center",
                              height: "100%",
                            }}
                          >
                            <img
                              src={
                                pack.toLowerCase() === "jazzcash"
                                  ? JazzcashSmallImg
                                  : pack.toLowerCase() === "easypaisa"
                                  ? EasypaisaSmallImg
                                  : pack.toLowerCase() === "credit card"
                                  ? CreditCardSmallImg
                                  : AlfaImg
                              }
                              alt={pack}
                              className={styles.mobilePaymentImage}
                            />
                            <Box sx={{ fontSize: "12px", fontWeight: "600" }}>
                              {pack.toLowerCase() === "jazzcash"
                                ? "Jazz Cash"
                                : pack.toLowerCase() === "credit card"
                                ? "Credit Card"
                                : pack}
                            </Box>
                          </Box>
                        </label>
                      </div>
                    </Grid>
                  ))}
                </Grid>

                {/* Mobile Number Input */}
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    marginTop: "2rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box className={styles.labels}>Mobile Number</Box>
                    <Box className={styles.labels}>موبائل نمبر</Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      padding: "1rem",
                      width: matches ? "342px" : "250px",
                      //border: formik.errors.mobileNumber
                      //  ? "1px solid red"
                      //  : "1px solid #dddddd",
                      borderRadius: "7px",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box sx={{ fontWeight: "bold" }}>{countryCode}</Box>

                    <InputMask
                      placeholder="333 1234567"
                      mask={inputMask}
                      maskChar=" "
                      value={formik.values.mobileNumber}
                      onChange={(e) => handleMobileChange(e, formik)}
                      disabled
                    >
                      {(inputProps) => (
                        <input
                          type="tel"
                          className={styles.inputField}
                          placeholder="333 1234567"
                          onKeyDown={(e) => handleKeyDown(e)}
                          
                        />
                      )}
                    </InputMask>
                  </Box>
                  <Box sx={{ color: "red", fontSize: "10px" }}>
                    {formik.errors.mobileNumber}
                  </Box>
                </Box>

                {/* Email Address */}
                {formik.values.paymentProvider.toLowerCase() === "easypaisa" && !sessionInfo.email && <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    marginTop: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box className={styles.labels}>Email Address</Box>
                    <Box className={styles.labels}>ای میل</Box>
                  </Box>
                  <input
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange("email")}
                    placeholder="john.doe@test.com"
                    className={
                      formik.errors.email
                        ? styles.emailInputError
                        : styles.emailInput
                    }
                  />
                  <Box sx={{ color: "red", fontSize: "10px" }}>
                    {formik.errors.email}
                  </Box>
                </Box>}

                {/* CNIC input */}
               {formik.values.paymentProvider.toLowerCase() === "jazzcash"  &&  
               !sessionInfo.cnic && 
               sessionInfo.jcServiceName !== "walee-jazzcash" && <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    marginTop: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box className={styles.labels}>CNIC#</Box>
                    <Box className={styles.labels}>شناختی کارڈ نمبر</Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      padding: "1rem",
                      width: matches ? "342px" : "250px",
                      border: formik.errors.cnic
                        ? "1px solid red"
                        : "1px solid #dddddd",
                      borderRadius: "7px",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <InputMask
                      mask="99999-9999999-9"
                      maskChar=" "
                      value={formik.values.cnic}
                      onChange={formik.handleChange("cnic")}
                    >
                      {(inputProps) => (
                        <input
                          type="tel"
                          className={styles.inputField}
                          placeholder="42000-0000000-0"
                          onKeyDown={(e) => handleKeyDown(e)}
                        />
                      )}
                    </InputMask>
                  </Box>
                  <Box sx={{ color: "red", fontSize: "10px" }}>
                    {formik.errors.cnic}
                  </Box>
                </Box>}
                {/* CTA */}
                <Box sx={{ width: "100%", marginTop: "1rem" }}>
                  <button
                    type="submit"
                    className={styles.primaryCta}
                    onClick={() => formik.handleSubmit()}
                  >
                    Continue
                  </button>
                </Box>
                {/* Desclaimer */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "0.5rem",
                  }}
                >
                  <Box className={styles.heading}>
                    We don't have any cash refund policy
                  </Box>
                  <Box sx={{ marginTop: "0.5rem" }} className={styles.heading}>
                    ہماری کیش ریفندپالیسی نہیں ہے
                  </Box>
                </Box>
              </CardContent>
              <WaitingModal
                open={waitingModalOpen}
                handleClose={handleWaitingModalClose}
                status={transactionStatus}
                errorMessage={errorMessage}
                matches={matches}
                paymentProvider={formik.values.paymentProvider}
              />
            </Card>
          </Box>
        )}
      </>
    );
  };

  const renderDesktop = () => {
    return (
      <>
        {!error && (
          <Box
            sx={{
              display: "flex",
              height: "90vh",
              overflow: "auto",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Card
              sx={{
                width: matches ? "414px" : "313px",
                overflow: "auto",
              }}
            >
              <CardContent>
                {/*Header Image*/}
                <Box
                  sx={{ width: matches ? "381px" : "297px", height: "95px" }}
                >
                  <img
                    src={HeaderImg}
                    alt="walee payment"
                    style={{
                      width: matches ? "381px" : "297px",
                      height: "95px",
                    }}
                  />
                </Box>
                {/*Instructions*/}
                <Box
                  sx={{
                    display: "flex",
                    marginY: "1rem",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      color: "#404040",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    Select Payment Type
                  </Box>
                  <Box
                    sx={{
                      color: "#404040",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    ادائیگی کا طریقہ منتخب کریں
                  </Box>
                </Box>
                {/*Payment Providers*/}

                <Grid container spacing={3}>
                  {sessionInfo.paymentMethods?.map((pack, index) => (
                    <Grid item lg={4} md={4} sm={12} key={index}>
                      <div
                        className={
                          formik.values.paymentProvider === pack
                            ? `${styles.priceBoxActive}`
                            : styles.priceBox
                        }
                      >
                        <label htmlFor={pack}>
                          <input
                            type="radio"
                            value={pack}
                            name="paymentProvider"
                            id={pack}
                            onChange={(e) => handlePaymentProviders(e, formik)}
                            hidden
                          />
                          <Box
                            sx={{
                              display: "flex",
                              cursor: "pointer",
                              flexDirection: "column",
                              gap: 2,
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={
                                pack.toLowerCase() === "jazzcash"
                                  ? JazzcashImg
                                  : pack.toLowerCase() === "easypaisa"
                                  ? EasypaisaImg
                                  : pack.toLowerCase() === "credit card"
                                  ? CreditCardImg
                                  : AlfaImg
                              }
                              alt={pack}
                              className={styles.paymentImage}
                            />
                            <Box sx={{ fontSize: "12px" }}>
                              {pack.toLowerCase() === "jazzcash"
                                ? "Jazz Cash"
                                : pack.toLowerCase() === "credit card"
                                ? "Credit / Debit Card"
                                : pack}
                            </Box>
                          </Box>
                        </label>
                      </div>
                    </Grid>
                  ))}
                </Grid>

                {/* Mobile Number Input */}
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    marginTop: "2rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#313131",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Mobile Number
                    </Box>
                    <Box
                      sx={{
                        color: "#313131",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      موبائل نمبر
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      padding: "1rem",
                      width: matches ? "342px" : "250px",
                      //border: formik.errors.mobileNumber
                      //  ? "1px solid red"
                      //  : "1px solid #dddddd",
                      
                      borderRadius: "7px",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box sx={{ fontWeight: "bold" }}>{countryCode}</Box>

                    <InputMask
                      placeholder="333 1234567"
                      mask={inputMask}
                      maskChar=" "
                      value={formik.values.mobileNumber}
                      onChange={(e) => handleMobileChange(e, formik)}
                      disabled
                     
                    >
                      {(inputProps) => (
                        <input
                          type="tel"
                          className={styles.inputField}
                          placeholder="333 1234567"
                          onKeyDown={(e) => handleKeyDown(e)}
                          
                        />
                      )}
                    </InputMask>
                  </Box>
                  <Box sx={{ color: "red", fontSize: "10px" }}>
                    {formik.errors.mobileNumber}
                  </Box>
                </Box>

                {/* Email Address */}
                {formik.values.paymentProvider.toLowerCase() === "easypaisa" && !sessionInfo.email && <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    marginTop: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#313131",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Email Address
                    </Box>
                    <Box
                      sx={{
                        color: "#313131",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      ای میل
                    </Box>
                  </Box>
                  <input
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange("email")}
                    placeholder="john.doe@test.com"
                    className={
                      formik.errors.email
                        ? styles.emailInputError
                        : styles.emailInput
                    }
                  />
                  <Box sx={{ color: "red", fontSize: "10px" }}>
                    {formik.errors.email}
                  </Box>
                </Box>}

                {/* CNIC input */}
               {formik.values.paymentProvider.toLowerCase() === "jazzcash" && 
               !sessionInfo.cnic && 
               sessionInfo.jcServiceName !== "walee-jazzcash" && <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    marginTop: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#313131",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      CNIC#
                    </Box>
                    <Box
                      sx={{
                        color: "#313131",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      شناختی کارڈ نمبر
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      padding: "1rem",
                      width: matches ? "342px" : "250px",
                      border: formik.errors.cnic
                        ? "1px solid red"
                        : "1px solid #dddddd",
                      borderRadius: "7px",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <InputMask
                      mask="99999-9999999-9"
                      maskChar=" "
                      value={formik.values.cnic}
                      onChange={formik.handleChange("cnic")}
                    >
                      {(inputProps) => (
                        <input
                          type="tel"
                          className={styles.inputField}
                          placeholder="42000-0000000-0"
                          onKeyDown={(e) => handleKeyDown(e)}
                        />
                      )}
                    </InputMask>
                  </Box>
                  <Box sx={{ color: "red", fontSize: "10px" }}>
                    {formik.errors.cnic}
                  </Box>
                </Box>}
                {/* CTA */}
                <Box sx={{ width: "100%", marginTop: "1rem" }}>
                  <button
                    type="submit"
                    className={styles.primaryCta}
                    onClick={() => formik.handleSubmit()}
                  >
                    Continue
                  </button>
                </Box>
                {/* Desclaimer */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "0.5rem",
                  }}
                >
                  <Box
                    sx={{
                      color: "#404040",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    We don't have any cash refund policy
                  </Box>
                  <Box
                    sx={{
                      color: "#404040",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    ہماری کیش ریفندپالیسی نہیں ہے
                  </Box>
                </Box>
              </CardContent>
              <WaitingModal
                open={waitingModalOpen}
                handleClose={handleWaitingModalClose}
                status={transactionStatus}
                errorMessage={errorMessage}
                matches={matches}
                paymentProvider={formik.values.paymentProvider}
              />
            </Card>
          </Box>
        )}
      </>
    );
  };
  return (
    <>
      {error && <ErrorScreen error={error} matches={matches} />}
      {mobileView ? renderMobile() : renderDesktop()}
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  sessionId: ownProps.match.params.sessionId,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getSessionInfo: hostedCheckoutActions.getSessionInfo,
  checkout: hostedCheckoutActions.checkout,
  checkoutWithEasypaisa: hostedCheckoutActions.checkoutWithEasypaisa,
  checkoutWithStripe: hostedCheckoutActions.creditCardCheckout
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const HostedCheckoutComponentRedux = connector(HostedCheckoutComponent);

export { HostedCheckoutComponentRedux as HostedCheckoutComponent };
