import React, { useState, useEffect, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Formik, FormikProps, FieldArray } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Switch from "@mui/material/Switch";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import PageHeader from "&styled/page-header";
import { RootState } from "&store/store";
import { servicesActions } from "&features/services/services.slice";
import SelectComponent from "&styled/form/select";
import { SubmitButton } from "&styled/button/button.component";
import { TextInput } from "&styled/textField/textField.component";
import { Select } from "@mui/material";

type ReduxProps = ConnectedProps<typeof connector>;
type OperatorsType = {
  country: string;
  name: string;
  mccmnc: string;
  createdDate: string;
};
type SelectFormtted = {
  label: string;
  value: string;
};
type CustomFields = {
  name: string;
  value: string;
};

const JC_TYPES = [
  { label: "Walee-Jazzcash", value: "walee-jazzcash" },
  { label: "Multiverse-Jazzcash", value: "multiverse-jazzcash" },
  { label: "Hashir-Jazzcash", value: "hashir-jazzcash" },
  { label: "Techlets-Jazzcash", value: "techlets-jazzcash" },
  { label: "Adonai-Jazzcash", value: "adonai-jazzcash" },
  // {label:"Elohim-Jazzcash", value:"elohim-jazzcash"},
];

const EP_TYPES = [
  { label: "Walee-Easypaisa", value: "walee-easypaisa" },
  { label: "Multiverse-Easypaisa", value: "multiverse-easypaisa" },
  { label: "Techlets-Easypaisa", value: "techlets-easypaisa" },
  { label: "Hashir-Easypaisa", value: "hashir-easypaisa" },
  { label: "Elohim-Easypaisa", value: "elohim-easypaisa" },
  { label: "Adonai-Easypaisa", value: "adonai-easypaisa" },
];

const PK_CREDIT_CARD_TYPES = [
  { label: "Stripe", value: "stripe" },
  { label: "Hashir Jazzcash Credit Card", value: "hashir-credit-card" },
  { label: "Multiverse Jazzcash Credit Card", value: "multiverse-credit-card" },
  { label: "Walee Credit Card", value: "walee-credit-card" },
];

const ServiceFormComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { getService, addService, editService, getServiceData, serviceId } = props;
  const [carriers, setCarriers] = useState<SelectFormtted[]>([]);
  const [merchants, setMerchants] = useState<SelectFormtted[]>([]);
  const [operators, setOperators] = useState<OperatorsType[]>([]);
  const [filteredOperators, setFilteredOperators] = useState<SelectFormtted[]>([]);
  const [selectedOperators, setSelectedOperators] = useState<string[]>([]);

  const [data, setData] = useState({
    _id: "",
    country: "",
    merchant: "",
    packages: [{ d: "", w: "", m: "", y: "", oneTime: "" }],
    serviceName: "",
    jcServiceName: "",
    epServiceName: "",
    ccServiceName: "",
    serviceProvider: "Easypaisa",
    operatorName: "",
    subscriptionFrequency: "",
    currency: "",
    price: "",
    disbursementAmount: "",
    autoDisburse: false,
    perTransactionCharge: "",
    epPerTransactionValues: {
      value: 0,
      percentage: false,
    },
    jcPerTransactionValues: {
      value: 0,
      percentage: false,
    },
    serviceType: "",
    notificationApi: "",
    tokenApi: "",
    portalUrl: "",
    carrierShare: "",
    merchantShare: "",
    waleeShare: "",
    clickIdApi: "",
    customFields: [{ name: "", value: "" }],
  });

  const validation = Yup.object().shape({
    country: Yup.string().required("Please select a country"),
    merchant: Yup.string().required("Please select merchant"),
    serviceProvider: Yup.string().required("Please select Carrier"),
    serviceName: Yup.string()
      .min(2, "Too short service name.")
      .max(50, "Too long service name")
      .required("Please provide valid service name"),
    currency: Yup.string()
      .min(2, "Provided currency is invalid")
      .max(4, "Provided currency is invalid")
      .required("Please provide currency"),
    serviceType: Yup.string().required("Please select service type"),
    notificationApi: Yup.string().optional(),
    tokenApi: Yup.string().optional(),
    portalUrl: Yup.string().optional(),
    carrierShare: Yup.number().required("Please provide valid carrier share"),
    merchantShare: Yup.number().required("Please provide valid merchant share"),
    waleeShare: Yup.number().required("Please provide valid walee share"),
    epPerTransactionValues: Yup.object().shape({
      value: Yup.number().required("Please provide valid value"),
      percentage: Yup.boolean().required("Please select type"),
    }),
    jcPerTransactionValues: Yup.object().shape({
      value: Yup.number().required("Please provide valid value"),
      percentage: Yup.boolean().required("Please select type"),
    }),
  });

  const fetchService = useCallback(
    async (id) => {
      try {
        const { payload: service } = await getService(id);
        setData({
          _id: service._id,
          country: service.country,
          merchant: service.merchant,
          packages: service.packages,
          customFields: service.customFields,
          serviceName: service.serviceName,
          jcServiceName: service.jcServiceName,
          epServiceName: service.epServiceName,
          ccServiceName: service.ccServiceName,
          serviceProvider: service.serviceProvider,
          operatorName: service.operatorName,
          subscriptionFrequency: service.subscriptionFrequency,
          currency: service.currency,
          price: service.price,
          autoDisburse: service.autoDisburse || false,
          perTransactionCharge: service.perTransactionCharge,
          epPerTransactionValues: service?.epPerTransactionValues,
          jcPerTransactionValues: service?.jcPerTransactionValues,
          disbursementAmount: service.disbursementAmount,
          notificationApi: service.notificationApi || "",
          tokenApi: service.tokenApi,
          clickIdApi: service.clickIdApi || "",
          portalUrl: service.portalUrl,
          carrierShare: service.carrierShare,
          waleeShare: service.waleeShare,
          merchantShare: service.merchantShare,
          serviceType: service.serviceType,
        });
        setSelectedOperators(service.paymentMethods);
        const filterOps = operators.filter((op) => op.country === service.country);
        const formatted: SelectFormtted[] = [];
        filterOps.forEach((op) => {
          formatted.push({ label: op.name, value: op.name });
        });
        setFilteredOperators(formatted);
      } catch (ex) {
        console.log(ex);
        alert("Something went wrong");
      }
    },
    [getService, operators]
  );

  useEffect(() => {
    const id = serviceId;
    if (id === "new") return;
    fetchService(id);
  }, [serviceId, fetchService]);

  useEffect(() => {
    (async () => {
      const { payload } = await getServiceData();
      const { merchants, carriers, operators } = payload;
      const formattedMerchants: SelectFormtted[] = [];
      const formattedCarriers: SelectFormtted[] = [];
      merchants.forEach((mer) => {
        formattedMerchants.push({ label: mer.name, value: mer._id });
      });
      carriers.forEach((mer) => {
        formattedCarriers.push({ label: mer.name, value: mer.name });
      });
      setMerchants(formattedMerchants);
      setCarriers(formattedCarriers);
      setOperators(operators);
    })();
  }, [getServiceData]);

  const getCountryOperators = async (e, formik) => {
    const val = e.target.value;
    formik.setFieldValue("country", val);
    const filterOps = operators.filter((op) => op.country === val);
    const formatted: SelectFormtted[] = [];
    filterOps.forEach((op) => {
      formatted.push({ label: op.name, value: op.name });
    });
    setFilteredOperators(formatted);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const clonedState = [...selectedOperators];
    const selectedOp = event.currentTarget.name;
    const index = clonedState.indexOf(selectedOp);
    if (index < 0) {
      setSelectedOperators((prevState) => [...prevState, selectedOp]);
    } else {
      setSelectedOperators(clonedState.filter((op) => op !== selectedOp));
    }
  };

  const handleSubmit = async (vals) => {
    vals["paymentMethods"] = selectedOperators;
    if (data._id) {
      await editService(vals);
      history.push("/services");
    } else {
      await addService(vals);
      history.push("/services");
    }
  };
  const countries = [
    { label: "Pakistan", value: "Pakistan" },
    { label: "China", value: "China" },
    { label: "Egypt", value: "Egypt" },
    { label: "Singapore", value: "Singapore" },
    { label: "Malaysia", value: "Malaysia" },
    { label: "United Kingdom", value: "United Kingdom" },
    { label: "United States", value: "United States" },
  ];

  const serviceTypes = [
    { label: "Loan", value: "Loan" },
    { label: "Others", value: "Others" },
  ];

  return (
    <>
      <PageHeader title="Service Form" />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          height: "80vh",
          overflow: "auto",
        }}
      >
        <Card>
          <CardContent>
            <Formik
              enableReinitialize={true}
              initialValues={data}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={(values: typeof data) => {
                handleSubmit(values);
              }}
              validationSchema={validation}
            >
              {(formik: FormikProps<typeof data>) => (
                <Box sx={{ width: "65%" }}>
                  <Grid container spacing={4} marginY={2}>
                    <Grid item lg={6} xs={12}>
                      <SelectComponent
                        value={formik.values.merchant}
                        onSelect={formik.handleChange("merchant")}
                        menuItems={merchants}
                        placeHolder="Select Merchant"
                        hasError={!!formik.errors.merchant}
                        errorMessage={formik.errors.merchant as string}
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <SelectComponent
                        value={formik.values.country}
                        onSelect={(e) => getCountryOperators(e, formik)}
                        menuItems={countries}
                        placeHolder="Select Country"
                        hasError={!!formik.errors.country}
                        errorMessage={formik.errors.country as string}
                      />
                    </Grid>
                  </Grid>
                  {filteredOperators.length > 0 && (
                    <Box sx={{ border: "1px solid #f1f1f1", padding: "1rem" }}>
                      <Box sx={{ color: "#3C3C3C", fontWeight: "bold" }}>Payment methods</Box>
                      {filteredOperators.map(({ label, value }, index) => (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          key={index}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 3,
                            }}
                          >
                            {label}
                          </Box>
                          <Switch
                            name={value}
                            checked={selectedOperators.includes(value)}
                            onChange={handleChange}
                            inputProps={{ "aria-label": "controlled" }}
                            color="success"
                          />
                        </Box>
                      ))}
                    </Box>
                  )}
                  <Box
                    sx={{
                      marginY: "1rem",
                      padding: "1rem",
                      border: "1px solid #f1f1f1",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#3C3C3C",
                        fontWeight: "bold",
                        marginY: "1rem",
                      }}
                    >
                      Service Info
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Service Name"}
                          value={formik.values.serviceName}
                          handleTextChange={formik.handleChange("serviceName")}
                          hasError={!!formik.errors.serviceName}
                          errorMessage={formik.errors.serviceName as string}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <SelectComponent
                          value={formik.values.serviceProvider}
                          onSelect={formik.handleChange("serviceProvider")}
                          menuItems={carriers}
                          placeHolder="Select Carrier"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <SelectComponent
                          value={formik.values.serviceType}
                          onSelect={formik.handleChange("serviceType")}
                          menuItems={serviceTypes}
                          placeHolder="Select Service Type"
                          hasError={!!formik.errors.serviceType}
                          errorMessage={formik.errors.serviceType as string}
                        />
                      </Grid>
                      {selectedOperators.includes("Jazzcash") && (
                        <Grid item lg={6} xs={12}>
                          <SelectComponent
                            value={formik.values.jcServiceName}
                            onSelect={formik.handleChange("jcServiceName")}
                            menuItems={JC_TYPES}
                            placeHolder="Select JazzCash Service"
                            hasError={!!formik.errors.jcServiceName}
                            errorMessage={formik.errors.jcServiceName as string}
                          />
                        </Grid>
                      )}
                      {selectedOperators.includes("Easypaisa") && (
                        <Grid item lg={6} xs={12}>
                          <SelectComponent
                            value={formik.values.epServiceName}
                            onSelect={formik.handleChange("epServiceName")}
                            menuItems={EP_TYPES}
                            placeHolder="Select Easypaisa Service"
                            hasError={!!formik.errors.epServiceName}
                            errorMessage={formik.errors.epServiceName as string}
                          />
                        </Grid>
                      )}
                      {selectedOperators.includes("Credit Card") && (
                        <Grid item lg={6} xs={12}>
                          <SelectComponent
                            value={formik.values.ccServiceName}
                            onSelect={formik.handleChange("ccServiceName")}
                            menuItems={PK_CREDIT_CARD_TYPES}
                            placeHolder="Select Credit Card Service"
                            hasError={!!formik.errors.ccServiceName}
                            errorMessage={formik.errors.ccServiceName as string}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                  {/*Billing Info*/}
                  <Box
                    sx={{
                      marginY: "1rem",
                      padding: "1rem",
                      border: "1px solid #f1f1f1",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#3C3C3C",
                        fontWeight: "bold",
                        marginY: "1rem",
                      }}
                    >
                      Billing Info
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Currency (e.g PKR)"}
                          value={formik.values.currency}
                          handleTextChange={formik.handleChange("currency")}
                          hasError={!!formik.errors.currency}
                          errorMessage={formik.errors.currency as string}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {/*Disbursement Info*/}
                  <Box
                    sx={{
                      marginY: "1rem",
                      padding: "1rem",
                      border: "1px solid #f1f1f1",
                      backgroundColor: "#fafafa",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#3C3C3C",
                        fontWeight: "bold",
                        marginY: "1rem",
                      }}
                    >
                      Disbursement
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item lg={6} xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "8.5px 14px",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                            borderRadius: "4px",
                          }}
                        >
                          <Box>Auto Disburse</Box>
                          <Switch
                            name={"autoDisburse"}
                            checked={formik.values.autoDisburse}
                            onChange={formik.handleChange("autoDisburse")}
                            inputProps={{ "aria-label": "controlled" }}
                            color="success"
                          />
                        </Box>
                      </Grid>
                      <Grid item lg={6}></Grid>
                      {formik.values.autoDisburse && (
                        <>
                          <Grid item lg={6} xs={12}>
                            <TextInput
                              placeHolder={"Disbursement Amount Limit"}
                              value={formik.values.disbursementAmount}
                              handleTextChange={(e) => formik.setFieldValue("disbursementAmount", parseInt(e))}
                              hasError={!!formik.errors.disbursementAmount}
                              errorMessage={formik.errors.disbursementAmount as string}
                              type="number"
                            />
                          </Grid>
                          <Grid item lg={6} xs={12}>
                          </Grid>
                          {/* <Grid item lg={6} xs={12}>
                            <TextInput
                              placeHolder={"Per Transaction Charge (%)"}
                              value={formik.values.perTransactionCharge}
                              handleTextChange={(e) => formik.setFieldValue("perTransactionCharge", parseFloat(e))}
                              hasError={!!formik.errors.disbursementAmount}
                              errorMessage={formik.errors.disbursementAmount as string}
                              type="number"
                            />
                          </Grid> */}
                          {/* For hidden fields because need to implement first backend */}
                          {/* EP Per Transaction */}
                          <Grid item lg={6} xs={12}>
                              <TextInput
                                placeHolder={"EP Per Transaction Charge"}
                                value={formik.values.epPerTransactionValues?.value?.toString()}
                                handleTextChange={(e) => {
                                  if (!isNaN(Number(e?.target?.value ?? 0))) {
                                    formik.setFieldValue("epPerTransactionValues.value", Number(e));
                                  }
                                }}
                                hasError={false}
                                errorMessage={""}
                                type="number"
                              />
                            </Grid>
                          <Grid item lg={6} xs={12}>
                              <SelectComponent
                                value={formik.values.epPerTransactionValues?.percentage?.toString()}
                                onSelect={(e) =>
                                  formik.setFieldValue("epPerTransactionValues.percentage", e.target.value === "true")
                                }
                                menuItems={[
                                  {
                                    label: "Percentage (%)",
                                    value: "true",
                                  },
                                  {
                                    label: "Fixed Price",
                                    value: "false",
                                  },
                                ]}
                                placeHolder="Select Type"
                                hasError={false}
                                errorMessage={""}
                              />
                            </Grid>
                          <Grid item lg={6} xs={12}>
                              <TextInput
                                placeHolder={"JC Per Transaction Charge"}
                                value={formik.values.jcPerTransactionValues?.value?.toString()}
                                handleTextChange={(e) => {
                                  if (!isNaN(Number(e?.target?.value ?? 0))) {
                                    formik.setFieldValue("jcPerTransactionValues.value", Number(e));
                                  }
                                }}
                                hasError={false}
                                errorMessage={""}
                                type="number"
                              />
                            </Grid>
                            <Grid item lg={6} xs={12}>
                              <SelectComponent
                                value={formik.values.jcPerTransactionValues?.percentage?.toString()}
                                onSelect={(e) =>
                                  formik.setFieldValue("jcPerTransactionValues.percentage", e.target.value === "true")
                                }
                                menuItems={[
                                  {
                                    label: "Percentage (%)",
                                    value: "true",
                                  },
                                  {
                                    label: "Fixed Price",
                                    value: "false",
                                  },
                                ]}
                                placeHolder="Select Type"
                                hasError={false}
                                errorMessage={""}
                              />
                            </Grid>
                        </>
                      )}
                    </Grid>
                  </Box>

                  {/*Client's APIs*/}
                  <Box
                    sx={{
                      marginY: "1rem",
                      padding: "1rem",
                      border: "1px solid #f1f1f1",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#3C3C3C",
                        fontWeight: "bold",
                        marginY: "1rem",
                      }}
                    >
                      Client API(s)
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Notification URI"}
                          value={formik.values.notificationApi}
                          handleTextChange={formik.handleChange("notificationApi")}
                          hasError={!!formik.errors.notificationApi}
                          errorMessage={formik.errors.notificationApi as string}
                          required={false}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Token URI"}
                          value={formik.values.tokenApi}
                          handleTextChange={formik.handleChange("tokenApi")}
                          hasError={!!formik.errors.tokenApi}
                          errorMessage={formik.errors.tokenApi as string}
                          required={false}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Click ID URI"}
                          value={formik.values.clickIdApi}
                          handleTextChange={formik.handleChange("clickIdApi")}
                          hasError={!!formik.errors.clickIdApi}
                          errorMessage={formik.errors.clickIdApi as string}
                          required={false}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Portal URL"}
                          value={formik.values.portalUrl}
                          handleTextChange={formik.handleChange("portalUrl")}
                          hasError={!!formik.errors.portalUrl}
                          errorMessage={formik.errors.portalUrl as string}
                          required={false}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {/*Revenue Shares*/}
                  <Box
                    sx={{
                      marginY: "1rem",
                      padding: "1rem",
                      border: "1px solid #f1f1f1",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#3C3C3C",
                        fontWeight: "bold",
                        marginY: "1rem",
                      }}
                    >
                      Revenue Shares
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Carrier Share"}
                          value={formik.values.carrierShare}
                          handleTextChange={formik.handleChange("carrierShare")}
                          hasError={!!formik.errors.carrierShare}
                          errorMessage={formik.errors.carrierShare as string}
                        />
                      </Grid>
                      <Grid item lg={6}></Grid>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Merchant Share"}
                          value={formik.values.merchantShare}
                          handleTextChange={formik.handleChange("merchantShare")}
                          hasError={!!formik.errors.merchantShare}
                          errorMessage={formik.errors.merchantShare as string}
                        />
                      </Grid>
                      <Grid item lg={6}></Grid>
                      <Grid item lg={6} xs={12}>
                        <TextInput
                          placeHolder={"Walee Share"}
                          value={formik.values.waleeShare}
                          handleTextChange={formik.handleChange("waleeShare")}
                          hasError={!!formik.errors.waleeShare}
                          errorMessage={formik.errors.waleeShare as string}
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {/*Custom Fields*/}
                  <Box
                    sx={{
                      marginY: "1rem",
                      padding: "1rem",
                      border: "1px solid #f1f1f1",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#3C3C3C",
                        fontWeight: "bold",
                        marginY: "1rem",
                      }}
                    >
                      Custom Fields
                    </Box>
                    <FieldArray
                      name="customFields"
                      render={({ remove, push }) => (
                        <Grid container spacing={2}>
                          {formik.values.customFields.map((field: any, index: number) => (
                            <React.Fragment key={index}>
                              <Grid item lg={5}>
                                <TextInput
                                  placeHolder={`Field Name`}
                                  value={field.name}
                                  required={false}
                                  handleTextChange={(e) => formik.setFieldValue(`customFields.${index}.name`, e)}
                                />
                              </Grid>
                              <Grid item lg={5}>
                                <TextInput
                                  placeHolder={`Field Value`}
                                  required={false}
                                  value={field.value}
                                  handleTextChange={(e) => formik.setFieldValue(`customFields.${index}.value`, e)}
                                />
                              </Grid>
                              <Grid item lg={2}>
                                <Box sx={{ display: "flex", gap: 2 }}>
                                  {index > 0 && (
                                    <IconButton onClick={() => remove(index)} color="secondary">
                                      <RemoveIcon />
                                    </IconButton>
                                  )}
                                  <IconButton onClick={() => push({ name: "", value: "" })} color="primary">
                                    <AddIcon />
                                  </IconButton>
                                </Box>
                              </Grid>
                            </React.Fragment>
                          ))}
                        </Grid>
                      )}
                    />
                  </Box>
                  <Box
                    sx={{
                      marginY: "1rem",
                      marginLeft: "1rem",
                      width: "15%",
                    }}
                  >
                    <SubmitButton title="Save" handlePress={() => formik.handleSubmit()} />
                  </Box>
                </Box>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  serviceId: ownProps.match.params.serviceId,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getService: servicesActions.getService,
  addService: servicesActions.addService,
  editService: servicesActions.editService,
  getServiceData: servicesActions.getServiceData,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const ServiceFormComponentRedux = connector(ServiceFormComponent);

export { ServiceFormComponentRedux as ServiceFormComponent };
