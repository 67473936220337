import React, { useState } from "react";
import Box from "@mui/material/Box";
import { currencies } from "&config/currencies";
import SelectComponent from "&styled/form/select";
import { SelectChangeEvent } from "@mui/material/Select";
import CustomDatePicker from "&styled/date-picker";
import CountriesWithFlags from "&styled/countries-with-flags";
import { SubmitButton } from "&styled/button/button.component";
import CreateIcon from "&assets/icons/create";

type PageProps = {
  title: string;
  onSearch?: Function;
  onAdd?: Function | null;
  addText?: string | null;
  type?: string;
  hasPrint?: boolean;
  onPrint?: Function;
  getDatesValues?: Function;
  defaultCurrency?: string;
  services?: any;
};
export default function PageHeader({
  title,
  onSearch,
  type,
  onAdd,
  hasPrint,
  onPrint,
  getDatesValues,
  defaultCurrency,
  addText,
  services
}: PageProps) {
  const [currency, setCurrency] = useState(defaultCurrency || "USD");
  const [service, setService] = useState("");
  const [carrier, setCarrier] = useState("");
  const [fromDate, setFromDate] = useState<Date | null>(new Date());
  const [toDate, setToDate] = useState<Date | null>(new Date());
  const [country, setCountry] = useState("PK");

  const CARRIERS = [
    {label: "Jazzcash", value: "JAZZCASH"},
    {label: "Easypaisa", value: "EASYPAISA"},
  ];

  const onCurrencyChange = (event: SelectChangeEvent) => {
    setCurrency(event.target.value as string);
  };

  const handleChangeService = (e) => {
    setService(e.target.value)
  }

  const handleChangeCarrier = (e) => {
    setCarrier(e.target.value)
  }

  const handleFromDate = (newValue) => {
    setFromDate(newValue);
    getDatesValues && getDatesValues(newValue, "dateFrom")
  };
  const handleToDate = (newValue) => {
    setToDate(newValue);
    getDatesValues && getDatesValues(newValue, "dateTo")
  };
  const onCountrySelect = (code) => {
    setCountry(code);
  };

  const makeSearch = async () => {
    let formattedCountry = "";
    if (country === "PK") {
      formattedCountry = "Pakistan";
    }
    const vals = {
      dateFrom: fromDate,
      dateTo: toDate,
      country: formattedCountry,
      service,
      carrier,
      currency,
    };
    if (onSearch) {
      await onSearch(vals);
    }
  };
  const renderTitle = () => {
    return (
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <CreateIcon />
        <Box>{`${addText || "Create Account"}`} </Box>
      </Box>
    );
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ color: "#3C3C3C", fontSize: "20px", fontWeight: "bold" }}>
        {title}
      </Box>
      {type && (
        <Box sx={{ display: "flex", gap: 2 }}>
          <SelectComponent
            menuItems={currencies}
            value={currency}
            onSelect={onCurrencyChange}
          />
          {type === "cash-in-out" && 
          <>
             <Box sx={{width: "15rem"}}>
              <SelectComponent
                menuItems={CARRIERS || []}
                value={carrier}
                onSelect={handleChangeCarrier}
                placeHolder="Select Carrier"
              />
          </Box>
             <Box sx={{width: "15rem"}}>
              <SelectComponent
                menuItems={services || []}
                value={service}
                onSelect={handleChangeService}
                placeHolder="Select Service"
              />
          </Box>
          </>
         }
          {type === "transaction" && (
            <CountriesWithFlags value={country} onSelect={onCountrySelect} />
          )}
          <CustomDatePicker
            label="Date From"
            value={fromDate}
            onChange={handleFromDate}
          />
         {type !== "cash-in-out" && <CustomDatePicker
            label="Date To"
            value={toDate}
            onChange={handleToDate}
          />}
          <Box sx={{ width: "20%" }}>
            {" "}
            <SubmitButton
              title="Search"
              handlePress={makeSearch}
              styles={{ height: "100%" }}
            />
          </Box>
        </Box>
      )}
      {!type && !hasPrint && onAdd && (
        <Box sx={{ width: "13%" }}>
          <SubmitButton
            title={renderTitle()}
            handlePress={onAdd ? onAdd : () => {}}
          />
        </Box>
      )}
      {hasPrint && (
        <Box sx={{ width: "15%" }}>
          <SubmitButton
            title="Print"
            handlePress={onPrint ? () => onPrint("page") : () => {}}
          />
        </Box>
      )}
    </Box>
  );
}
