import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { history } from "&store/store";

import { RootState } from "&store/store";
import { LoginComponent } from "&features/login/login.component";

import { languageActions } from "./redux/language";
import { LandingComponent } from "&features/landing/landing.component";
import { CreditCardRedirectComponent } from "&features/creditCardRedirect/creditCardRedirect.component";
import { HostedCheckoutComponent } from "&features/hostedCheckout/hostedCheckout.component";
import { CreditCardRedirectSuccessComponent } from "&features/creditCardRedirect/Success";
import { CreditCardRedirectFailedComponent } from "&features/creditCardRedirect/Failed";
import { CheckoutLinkComponent } from "&features/packages/checkoutLink";

type ReduxProps = ConnectedProps<typeof connector>;

const AuthRouter = (props: ReduxProps) => {
  return (
    <>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={LandingComponent} />
          <Route exact path="/login" component={LoginComponent} />
          <Route
            exact
            path="/callback/stripe/success/:orderId/:serviceId"
            component={CreditCardRedirectSuccessComponent}
          />
          <Route
            exact
            path="/callback/stripe/fail/:orderId/:serviceId"
            component={CreditCardRedirectFailedComponent}
          />
           <Route
            exact
            path="/checkout/via-link/:id"
            component={CheckoutLinkComponent}
          />
          <Route exact path="/easypaisa/cc/:serviceId/:msisdn/:product" component={CreditCardRedirectComponent} />
          <Route exact path="/hosted-checkout/:sessionId" component={HostedCheckoutComponent} />
         <Route path="/404" render={() => <div>page not found</div>} />
          <Redirect to="/login" />
        </Switch>
      </Router>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  language: state.language.language,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  setLanguage: languageActions.setLanguage,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const AuthRouteredux = connector(AuthRouter);

export { AuthRouteredux as AuthRouter };
