import React from "react";
import Box from "@mui/material/Box";

type PageProps = {
  title: string;
  onSearch?: Function;
 renderCta?: any;
};
export default function PageHeader({
  title,
  onSearch,
  renderCta
}: PageProps) {
 
 

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ color: "#3C3C3C", fontSize: "20px", fontWeight: "bold" }}>
        {title}
      </Box>
      
        {renderCta && renderCta() }
    
      
    </Box>
  );
}
