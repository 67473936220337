import React from "react";
import Box from "@mui/material/Box";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

interface Props {
  title: string;
  subTitleBlack?: string;
  subTitleDark: string;
  subTitleLight: string;
  dataKeyBlack?: string;
  dataKeyDark: string;
  dataKeyLight: string;
  heading: string;
  isCurrency: boolean;
  data: Array<any>;
}

function formatXAxis(tickItem) {
  return moment(tickItem).format("DD MMM YYYY");
}

function formatCurrency(number) {
  const suffixes = ["", "K", "M", "B", "T"]; // Add more suffixes as needed
  const thousand = 1000;

  if (number < thousand) {
    // If the number is less than a thousand, no suffix needed
    return number.toLocaleString("en-PK", { minimumFractionDigits: 0, maximumFractionDigits: 2 });
  }
  // Determine the magnitude of the number
  const magnitude = Math.floor(Math.log10(number) / 3);

  // Calculate the adjusted number and suffix
  const adjustedNumber = number / Math.pow(thousand, magnitude);
  const suffix = suffixes[magnitude];

  // Format the adjusted number with the appropriate number of decimal places
  const formattedNumber = adjustedNumber.toLocaleString("en-PK", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return `${formattedNumber}${suffix}`;
}

const renderYAxis = (isCurrency: boolean) => {
  let contents;
  if (isCurrency) {
    contents = (
      <YAxis
        axisLine={false}
        tickLine={false}
        tickFormatter={formatCurrency}
        tick={{ fontSize: 12, fill: "#111111" }}
      />
    );
  } else {
    contents = (
      <YAxis
        axisLine={false}
        tickLine={false}
        tick={{ fontSize: 12, fill: "#111111" }}
      />
    );
  }
  return contents;
};
const LineChartComponent = (props: Props) => {
  const {
    title,
    subTitleBlack,
    subTitleDark,
    subTitleLight,
    dataKeyBlack,
    dataKeyDark,
    dataKeyLight,
    heading,
    isCurrency,
    data,
  } = props;
  return (
    <Box
      sx={{
        borderRadius: "8px",
        height: "370px",
        width: "100%",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        justifyContent: "space-between",
        padding: "1.5rem",
      }}
    >
      <Box
        sx={{
          color: "#111111",
          fontWeight: "600",
          fontSize: "16px",
          marginBottom: "1rem",
        }}
      >
        {title}
      </Box>
      <Box sx={{ display: "flex", gap: 3 }}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Box
            sx={{
              width: "12px",
              height: "12px",
              borderRadius: "100px",
              background: "#6631F7 0% 0% no-repeat padding-box",
            }}
          ></Box>
          <Box>{subTitleDark}</Box>
        </Box>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Box
            sx={{
              width: "12px",
              height: "12px",
              borderRadius: "100px",
              background: "#CAB2FF 0% 0% no-repeat padding-box",
            }}
          ></Box>
          <Box>{subTitleLight}</Box>
        </Box>
     {subTitleBlack &&   <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Box
            sx={{
              width: "12px",
              height: "12px",
              borderRadius: "100px",
              background: "#000000 0% 0% no-repeat padding-box",
            }}
          ></Box>
          <Box>{subTitleBlack}</Box>
        </Box>}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
        <Box
          sx={{
            fontSize: "12px",
            WebkitTransform: "rotate(-90deg)",
            MozTrasform: "rotate(-90deg)",
            whiteSpace: "nowrap",
            writingMode: "lr-tb",
            marginLeft: "-2rem",
            fontWeight: "bold",
          }}
        >
          {heading}
        </Box>
        {/*Chart*/}

        <ResponsiveContainer width="100%" height="85%" >
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 10,
              left: 10,
              bottom: 10,
            }}
          >
            <CartesianGrid strokeDasharray="0" vertical={false} />
            <XAxis
              dataKey="date"
              axisLine={false}
              tickLine={false}
              tickFormatter={formatXAxis}
              tick={{ fontSize: 12, fill: "#111111" }}
            />
            {renderYAxis(isCurrency)}
            <Tooltip formatter={(value: any) => new Intl.NumberFormat('en').format(value)}/>
            <Line
              type="linear"
              dataKey={dataKeyDark}
              stroke="#6631F7"
              activeDot={{ r: 1 }}
              dot={{ strokeWidth: 5, r: 1 }}
            />
            <Line type="linear" dataKey={dataKeyLight} stroke="#CAB2FF" />
          {dataKeyBlack && <Line type="linear" dataKey={dataKeyBlack} stroke="#000000" />}
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export { LineChartComponent };
