import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import LinkIcon from '@mui/icons-material/Link';
import DeleteIcon from "&assets/icons/delete";
import EditIcon from "&assets/icons/edit";
import CardContent from "@mui/material/CardContent";
import { useHistory } from "react-router-dom";
import PageHeader from "&styled/page-header";
import FabButton from "&styled/FabButton";
import { RootState } from "&store/store";
import { packagesActions } from "./packages.slice";
import LinkModal from "./linkModal";
import { errorAlert, infoAlert, successAlert, warningAlert } from "&config/swalGenerator";

type ReduxProps = ConnectedProps<typeof connector>;

const PackagesComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { state, getPackages, deletePackage } = props;
  const { data } = state;
  const [pageSize, setPageSize] = React.useState(25);
  const [modalOpen, setModalOpen] = useState(false);
  const [link, setLink] = useState("")

  const handleCloseModal = () => {
    setLink("")
    setModalOpen(false);
 };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Package Name",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "callbackUrl",
      headerName: "Callback",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "serviceId",
      headerName: "Service ID",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      renderCell: ({ row }) => moment(row.createdDate).format("DD MMM YYYY"),
      headerAlign: "center",
      align: "center",
    },

    {
      field: "actions",
      headerName: "",
      renderCell: ({ row }) => renderButtons(row),
      width: 200,
      headerAlign: "center",
      align: "center",
    },
  ];

  useEffect(() => {
    (async () => {
      await getPackages();
    })();
  }, [getPackages]);

  const handleLinkGenerate = async(row) => {
     const lnk = `${process.env.REACT_APP_BASE_FRONTEND}/checkout/via-link/${row._id}`
     setLink(lnk)
     setModalOpen(true);
  }

  const onDelete = async(row) => {
    const value = await warningAlert();
    if (value) {
      try {
        await deletePackage(row._id);
        //frontendFunc(data.filter((val) => val._id !== row._id));
        await successAlert("Deleted", `Package has been deleted successfully!`);
      } catch (ex:any) {
        if (ex.response && ex.response.status === 404) {
          return infoAlert("Not Found!", `Package not found`);
        }
        await errorAlert();
      }
    }
  }

  const renderButtons = (row) => {
    return <Box sx={{ display: "flex", gap: 2 }}>
      {renderBtnEdit(row)}
      {renderBtnLink(row)}
      {renderBtnDelete(row)}
      </Box>;
  };

  const renderBtnEdit = (row) => {
    return (
      <FabButton
        label="Edit"
        onClick={(e: React.MouseEvent) =>
          history.push(`/packages/form/${row._id}`)
        }
      >
        <EditIcon />
      </FabButton>
    );
  };
  const renderBtnLink = (row) => {
    return (
      <FabButton
        label="Link"
        onClick={(e: React.MouseEvent) =>
          handleLinkGenerate(row)
        }
      >
        <LinkIcon sx={{color:"rgb(102, 49, 247)"}}/>
      </FabButton>
    );
  };

  const renderBtnDelete = (row) => {
    return (
      <FabButton
        label="delete"
        onClick={(e: React.MouseEvent) =>
          onDelete(row)
        }
      >
        <DeleteIcon/>
      </FabButton>
    );
  };

  return (
    <>
      <PageHeader
        title="Packages"
        onAdd={() => history.push("/packages/form/new")}
        addText="Create Package"
      />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: "4px solid #6631F7",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>
            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                rows={data}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[25, 50, 100]}
                getRowId={(row) => row._id}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                disableSelectionOnClick
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </div>
          </CardContent>
        </Card>
      </Box>
      <LinkModal open={modalOpen} handleClose={handleCloseModal} link={link}/>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  state: state.packages,
  token: state.login.token,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getPackages: packagesActions.getPackages,
  deletePackage: packagesActions.deletePackage,
  generateLink: packagesActions.generateLink
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const PackagesComponentRedux = connector(PackagesComponent);

export { PackagesComponentRedux as PackagesComponent };
